const CustomerActionTypes = {
  LOGOUT: 'CUSTOMER_LOGOUT',
  LOAD: 'CUSTOMER_LOADED',
  CHANGE: 'CUSTOMER_CHANGE',
  CREATE: 'CUSTOMER_CREATE',
  CARD_BALANCE: 'CUSTOMER_CARD_BALANCE',
  UPDATE: 'CUSTOMER_UPDATE',
  SET_STORE: 'CUSTOMER_SET_DEFAULT_STORE',
  CARDS_LIST: 'CUSTOMER_CARDS_LIST',
  CARD_REMOVE: 'CUSTOMER_CARD_REMOVE',
  CARD_UPDATE: 'CUSTOMER_CARD_UPDATE',
  CARD_VALIDATE: 'CUSTOMER_CARD_VALIDATE',
  CARD_LOADED: 'CUSTOMER_CARD_LOADED',
  CARD_ADD: 'CUSTOMER_CARD_ADD',
  CARD_REPLACE: 'CUSTOMER_CARD_REPLACE',
};

export default CustomerActionTypes;
