import ModalActionTypes, { modalStatuses } from './action-types';
import { initialModal } from '../initial-state';

/**
 * Cart reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const ModalReducer = (state, action) => {
  let newState = {
    ...state,
  };

  switch (action.type) {
    case ModalActionTypes.OPEN:
      newState = {
        ...action.payload,
        status: modalStatuses.OPEN,
      };
      // console.log('modalOpen', newState);
      return newState;

    case ModalActionTypes.CLOSE:
      newState = initialModal();
      return newState;

    default:
      return newState;
  }
};

export default ModalReducer;
