const RecipeFilterActions = {
  ADD_RECIPE_FILTER: 'ADD_RECIPE_FILTER',
  REMOVE_RECIPE_FILTER: 'REMOVE_RECIPE_FILTER',
  SET_RECIPE_PAGE: 'SET_RECIPE_PAGE',
  CLEAR_ALL_RECIPE_FILTERS: 'CLEAR_ALL_RECIPE_FILTERS',
};

export default RecipeFilterActions;

export const AddRecipeFilter = (tid) => ({
  type: RecipeFilterActions.ADD_RECIPE_FILTER,
  payload: tid,
});

export const RemoveRecipeFilter = (tid) => ({
  type: RecipeFilterActions.REMOVE_RECIPE_FILTER,
  payload: tid,
});

export const SetRecipePage = (page) => ({
  type: RecipeFilterActions.SET_RECIPE_PAGE,
  payload: page,
});

export const ClearAllRecipeFilters = () => ({
  type: RecipeFilterActions.CLEAR_ALL_RECIPE_FILTERS,
});
