import addressReducer from './address/reducer';
import cartReducer from './cart/reducer';
import checkoutReducer from './checkout/reducer';
import classFiltersReducer from './classFilters/reducer';
import customerReducer from './customer/reducer';
import modalReducer from './modal/reducer';
import uiReducer from './ui/reducer';
import userReducer from './user/reducer';
import myAccountReducer from './myAccount/reducer';
import { LOCAL_STORAGE_KEY, emptyCart } from './initial-state';
import recipeFiltersReducer from './recipeFilters/reducer';

const MainReducer = (state, action) => {
  const newState = {
    address: addressReducer(state.address, action),
    cart: cartReducer(state.cart, action),
    checkout: checkoutReducer(state.checkout, action),
    classFilters: classFiltersReducer(state.classFilters, action),
    recipeFilters: recipeFiltersReducer(state.recipeFilters, action),
    customer: customerReducer(state.customer, action),
    modal: modalReducer(state.modal, action),
    myAccount: myAccountReducer(state.myAccount, action),
    ui: uiReducer(state.ui, action),
    user: userReducer(state.user, action),
  };

  // For FMK removal, don't store the cart
  // TODO: LON-1460 When a dynamic way to handle FMK is introduced, must remove this
   newState.cart.fmk = emptyCart().fmk;

  // Set local storage.
  setTimeout(() => {
    try {
      if (localStorage) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));
      }
    } catch (e) {
    }
  }, 1);

  return newState;
};

export default MainReducer;
