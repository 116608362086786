// @flow
import { defaults, flatMapDeep, isEmpty, mapValues, omitBy } from 'lodash';
import { stringify } from 'querystring';
import { doRequest } from './doRequest';
import type {
  CardBalance,
  ContactCaseCreate,
  CrmResponse,
  CustomHeaders,
  Customer,
  CustomerCreate,
  CustomerUpdate,
  DonationRequestCreate,
  Headers,
  LoginCredentials,
  NewsletterSubscribeCreate,
  PasswordReset,
  PasswordUpdate,
  Token,
  Transaction,
  TransactionCreate,
  UserSession,
} from './flow-typed/crmClientServiceTypes';
import { envLevel, API_TYPE, getApiPath } from '../libs/api';

const generalErrorMessage = 'We are unable to process your request, please try again.';
let recaptcha_token: string;

export class baseCrmClientService {
  headers: Headers;
  headersPost: Headers;
  recaptcha_sitekey: string;
  static instance: baseCrmClientService;

  constructor() {
    // Protect that multiples instances are created.
    if (baseCrmClientService.instance) {
      return baseCrmClientService.instance;
    }

    this.headers = {
      'User-Agent': 'Longos Web SPA',
      'Content-Type': 'application/json',
      'X-API-Key': '',
    };

    this.headersPost = {
      ...this.headers,
    };

    this.recaptcha_sitekey = '6LczgcQUAAAAABSNksYopW4kceR6LYFfL19pa4H8';
    recaptcha_token = '';

    // Store instance, for singleton handling.
    baseCrmClientService.instance = this;
  }

  useWebHeaders() {
    let apiKey = 'df34cde4-f145-4334-98de-787d2844dbde';
    // Different API Key for live envs.
    if (envLevel() === 'live') {
      apiKey = '24c43ae2-1eac-4e79-8924-cf5270c49242';
    }

    this.headers['X-API-Key'] = apiKey;
    this.headersPost['X-API-Key'] = apiKey;
  }

  useAppHeaders() {
    let apiKey = '1a9821d1-9ba3-4080-9a68-7878684b51c5';
    // Different API Key for live envs.
    if (envLevel() === 'live') {
      apiKey = '455baf44-3bb9-4cb4-bcfa-4d765ee6ec4f';
    }

    this.headers['X-API-Key'] = apiKey;
    this.headersPost['X-API-Key'] = apiKey;
  }

  parseResponseError(response: CrmResponse) {
    let extendError = {
      message: '',
      fullErrors: [],
    };

    // On simple string error, use it as message.
    if (typeof response === 'string') {
      extendError.message = response;
      return extendError;
    }

    // On missing ResponseBody, fallback to alternative errors.
    if (response.ResponseBody === undefined) {
      // Fallback to use error_description when available.
      if (typeof response.error_description === 'string') {
        extendError.message = response.error_description;
      }

      return extendError;
    }

    // At this point ResponseBody is available, parse the errors.
    try {
      const parseBody = JSON.parse(response.ResponseBody);
      const errors = flatMapDeep(parseBody, 'Errors');
      // Map errors with CRM field key and return at fullErrors property, this
      // is a simplified associative error structure easy to consume in
      // components to map error message to field that received the violation.
      let fullErrors = mapValues(parseBody, 'Errors');
      // Keep only the errors items with message.
      extendError.fullErrors = omitBy(fullErrors, isEmpty);

      // Composed message which extract messages from all messages and
      // concatenate into single string for cases when components don't need to
      // show individual message for each field.
      if (Array.isArray(errors)) {
        const errorMessages = errors.map((error) => error.ErrorMessage);
        extendError.message = errorMessages.join(' - ');
      }
    } catch (error) {
      extendError.message = response.ResponseBody;
    }

    return extendError;
  }

  v3Callback = (token) => {
    if (typeof token === 'string') {
      // console.log(token);
      this.setRecaptchaToken(token);
    }
  };

  setRecaptchaToken(val) {
    recaptcha_token = val;
  }

  resetRecaptchaToken() {
    recaptcha_token = '';
  }

  async doRequest(
    method: string,
    resource: string,
    data: Object | string,
    customHeaders: CustomHeaders = {}
  ) {
    let url = getApiPath(API_TYPE.crm, resource);
    let postData = data;

    if (method === 'GET') {
      url = url + encodeURI(data || '');
      postData = null;
    }
    const headers = defaults(customHeaders, data === null ? this.headers : this.headersPost);

    if ((method === 'POST' || method === 'PUT') && recaptcha_token !== '') {
      headers.recaptcha_token = recaptcha_token;
    }

    let errorObject = {};
    try {
      const responseData = await doRequest({
        headers,
        method,
        url,
        data: postData,
      });

      // If response don't have a ResponseCode we cannot difference
      // success/error wo we assume is success. This apply for login which is
      // handled by Microsoft authentication endpoint that don't follow Vlad
      // standard response structure.
      if (typeof responseData.ResponseCode === 'undefined') {
        return responseData;
      }

      // The response has a success code, so return data.
      if (responseData.ResponseCode === '000') {
        return responseData;
      }

      // Response was success 200 or 201 but with failed code so should be
      // handled as error.
      errorObject = {
        ...responseData,
        message: responseData.ResponseBody || responseData || generalErrorMessage,
      };
    } catch (requestError) {
      // Catch error in order to convert response to a JS object.
      try {
        // Error message resolves in an object.
        const responseError = await JSON.parse(requestError.message);
        const expandResponseError = this.parseResponseError(responseError);

        // Copy relevant error message to standard 'message' error object property or
        // fallback to generic message if none found in known properties.
        errorObject = {
          ...responseError,
          ...expandResponseError,
          message: expandResponseError.message || generalErrorMessage,
          requestStatus: requestError.requestStatus,
        };
      } catch (parseError) {
        // Just a plain message so wrap in custom object.
        errorObject = {
          message: requestError.message || generalErrorMessage,
          requestStatus: requestError.requestStatus,
        };
      }
    }

    // Massaged error object ready for consumption at components.
    throw errorObject;
  }

  /**
   * Create CRM customer.
   *
   * @param {Customer} customer - A customer object.
   * @returns {Promise<string>} Promise that resolves an email confirm token on
   * sucessful creation or empty string otherwise.
   * @memberof crmClientService
   */
  async customerCreate(customer: CustomerCreate): Promise<string> {
    const response = await this.doRequest('POST', 'Customers', customer);

    if (response && response.ResponseCode === '000') {
      try {
        const payload = JSON.parse(response.ResponseBody);
        const confirmUrl = new URL(payload.TSV_TokenLink);
        return confirmUrl.searchParams.get('t') || '';
      } catch (e) {
        // If parse failed, debug mode is disabled and token not available.
        return '';
      }
    }

    return '';
  }

  /**
   * Update a CRM customer fields.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {CustomerUpdate} customer - Customer update object.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<Customer>} - Promise that resolves updated customer
   * object on sucessful update or empty object otherwise.
   * @memberof crmClientService
   */
  async customerUpdate(
    customerId: string,
    customer: CustomerUpdate,
    userToken: string
  ): Promise<Customer> {
    const response = await this.doRequest(
      'PUT',
      `Customers/${customerId}`,
      customer,
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      const payload = JSON.parse(response.ResponseBody);
      return payload;
    }

    return {};
  }

  /**
   * Get a customer by ID.
   *
   * @param {string} customerId - The customer CRM ID.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<Customer>} - Promise that resolves a customer object.
   * @memberof crmClientService
   */
  async customerGet(customerId: string, userToken: string): Promise<Customer> {
    const response = await this.doRequest(
      'GET',
      'Customers/' + customerId,
      '',
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      const payload = JSON.parse(response.ResponseBody);
      return defaults(
        // Cast store ID to number.
        { PreferredStoreNumber: Number(payload.PreferredStoreNumber) },
        payload
      );
    }

    return {};
  }

  /**
   * Check if user session token is valid or not.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolve validation check, true
   * if valid, false otherwise.
   * @memberof crmClientService
   */
  async userSessionValidate(customerId: string, userToken: string): Promise<boolean> {
    const response = await this.doRequest(
      'GET',
      `Users/${customerId}/Token`,
      '',
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Validate that email confirm token is valid.
   *
   * @param {Token} token - Email confirm token.
   * @returns {Promise<CrmResponse>} - Promise that resolves CrmResponse.
   * @memberof crmClientService
   */
  async emailTokenValidate(token: Token): Promise<CrmResponse> {
    return this.emailTokenConfirm(token);
  }

  /**
   * Complete Password Reset token verification process.
   *
   * @param {Token} token
   * @returns {Promise<CrmResponse>} - Promise that resolves true if confirmed,
   * false otherwise.
   * @memberof crmClientService
   */
  async passwordTokenConfirm(token: Token): Promise<CrmResponse> {
    const response = await this.doRequest('PUT', 'Customers/Password/Token', token);

    if (response) {
      return response;
    }

    return {};
  }

  /**
   * Complete email confirm process.
   *
   * @param {Token} token
   * @returns {Promise<CrmResponse>} - Promise that resolves CrmResponse.
   * @memberof crmClientService
   */
  async emailTokenConfirm(token: Token): Promise<CrmResponse> {
    const response = await this.doRequest('PUT', 'Customers/Token', token);

    if (response) {
      return response;
    }

    return {};
  }

  /**
   * Retrieve the email address associated with an expired token.
   *
   * @param {Token} token
   * @returns {Promise<CrmResponse>} - Promise that resolves true if confirmed,
   * false otherwise.
   * @memberof crmClientService
   */
  async retrieveNewRegisterConfirmToken(token: Token): Promise<CrmResponse> {
    const response = await this.doRequest('POST', 'Customers/Token', token);

    if (response) {
      return response;
    }

    return {};
  }

  /**
   * Retrieve the email address associated with an expired token.
   *
   * @param {Token} token
   * @returns {Promise<CrmResponse>} - Promise that resolves true if confirmed,
   * false otherwise.
   * @memberof crmClientService
   */
  async retrieveNewPasswordResetToken(token: Token): Promise<CrmResponse> {
    const response = await this.doRequest('POST', 'Customers/Password/Token', token);

    if (response) {
      return response;
    }

    return {};
  }

  /**
   * Resend email confirmation token (Registration).
   *
   * @param {string} email - Customer registered email.
   * @returns {Promise<CrmResponse>} - Promise that resolve confirm token if resend
   * completes, empty string otherwise.
   * @memberof crmClientService
   */
  async registrationEmailTokenResend(email: string): Promise<CrmResponse> {
    const response = await this.doRequest('POST', `Customers/${email}/Token`, '');

    if (response) {
      return response;
    }

    return {};
  }

  /**
   * Resend email confirmation token (Forgot Password).
   *
   * @param {string} email - Customer registered email.
   * @returns {Promise<CrmResponse>} - Promise that resolve confirm token if resend
   * completes, empty string otherwise.
   * @memberof crmClientService
   */
  async resetPasswordEmailTokenResend(email: string): Promise<CrmResponse> {
    const response = await this.doRequest('POST', `Customers/${email}/Password/Token`, '');

    if (response) {
      return response;
    }

    return {};
  }

  /**
   * Login user to CRM authentication service.
   *
   * @param {LoginCredentials} credentials - User credentials.
   * @returns {Promise<UserSession>} - Promise that resolves user session
   * on complete login, empty object otherwise.
   * @memberof crmClientService
   */
  async userLogin(credentials: LoginCredentials): Promise<UserSession> {
    // Due limitation of Microsoft OAuth, Valdimir couldn't allow standard
    // application/json data input type for this endpoint.
    const customHeaders: CustomHeaders = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const response = await this.doRequest(
      'POST',
      'Users/Token',
      stringify(credentials),
      customHeaders
    );
    if (response && response.access_token !== undefined) {
      return response;
    }

    return {};
  }

  /**
   * Logout user from CRM, currently this endpoint does nothing.
   *
   * This endpoint should be called at user logout so in future CRM release a
   * token invalidation will be implemented to ensure token invalidation and
   * proper user session close.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolves true when session
   * closed properly, false otherwise.
   * @memberof crmClientService
   */
  async userLogout(customerId: string, userToken: string): Promise<boolean> {
    const response = await this.doRequest(
      'DELETE',
      `Users/${customerId}/Token`,
      '',
      this.addBearerHeader(userToken)
    );

    // This endpoint don't return data, response is empty array.
    if (Array.isArray(response)) {
      return true;
    }

    return false;
  }

  /**
   * Update user password.
   *
   * @param {string} customerId - CRM Customer ID.
   * @param {PasswordUpdate} password - Password update payload.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolves true in successful
   * password update, false otherwise.
   * @memberof crmClientService
   */
  async passwordUpdate(
    customerId: string,
    password: PasswordUpdate,
    userToken: string
  ): Promise<boolean> {
    const response = await this.doRequest(
      'PUT',
      `Customers/${customerId}/Password`,
      password,
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Request user password reset token or code.
   *
   * @param {string} email - CRM customer email.
   * @param {String} mode - Reset mode.
   * @returns {Promise<string>} - Promise that resolves reset token on valid
   * reset request or empty string otherwise.
   * @memberof crmClientService
   */
  async passwordReset(email: string, mode: string = 'token'): Promise<string> {
    const response = await this.doRequest('POST', `Customers/${email}/Password/Token`);

    if (response && response.ResponseCode === '000') {
      try {
        const payload = JSON.parse(response.ResponseBody);
        const resetUrl = new URL(payload.PR_TokenLink);
        const token = resetUrl.searchParams.get('t') || '';
        const code = resetUrl.searchParams.get('code') || '';

        if (mode === 'token') {
          return token;
        } else if (mode === 'code') {
          return code;
        }
      } catch (e) {
        // If parse failed, debug mode is disabled and token not available.
        return '';
      }
    }

    return '';
  }

  /**
   * Complete user password reset.
   *
   * @param {PasswordReset} reset - Password reset payload.
   * @returns {Promise<boolean>} - Promise that resolves true on successful new
   * password set, false otherwise.
   * @memberof crmClientService
   */
  async passwordResetComplete(reset: PasswordReset): Promise<boolean> {
    try {
      const response = await this.doRequest('PUT', 'Customers/Password', reset);
      if (response && response.ResponseCode === '000') {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  /**
   * Validate that TYR card number is valid.
   *
   * @param {string} cardNumber - TYR card number.
   * @returns {Promise<boolean>}
   * @memberof crmClientService
   */
  async tyrCardValidate(cardNumber: string): Promise<boolean> {
    const response = await this.doRequest('GET', `TYRCards/${cardNumber}`);

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Get TRY card points balance.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<CardBalance>} - Promise that resolve card balance
   * object when card number owned by customer exists, empty object otherwise.
   * @memberof crmClientService
   */
  async tyrCardPointsGet(customerId: string, userToken: string): Promise<CardBalance> {
    const response = await this.doRequest(
      'GET',
      `Customers/${customerId}/TYRCards/Points`,
      '',
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      const payload = JSON.parse(response.ResponseBody);
      return payload;
    }

    return {};
  }

  /**
   * Transfer TYR active card balance to new TRY card.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {string} cardNumber - TRY new card number, must be an inactive card
   * not linked yet with any customer account.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<CrmResponse>} - Promise that resolve true if linked card was
   * successfully removed, false otherwise.
   * @memberof crmClientService
   */
  async tryCardTransfer(
    customerId: string,
    cardNumber: string,
    cardData: any,
    userToken: string
  ): Promise<CrmResponse> {
    const transferResponse = await this.doRequest(
      'DELETE',
      `Customers/${customerId}/TYRCards/${cardNumber}`,
      cardData,
      this.addBearerHeader(userToken)
    );

    if (!!transferResponse && !!transferResponse.ResponseCode) {
      return transferResponse;
    }

    return {};
  }

  /**
   * Link TRY card to customer account.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {string} cardNumber - TRY card number, must be an inactive card not
   * linked yet with any customer account.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<CrmResponse>} - Promise that resolve true if card was
   * successfully linked, false otherwise.
   * @memberof crmClientService
   */
  async tryCardLink(
    customerId: string,
    cardNumber: string,
    cardData: any,
    userToken: string
  ): Promise<CrmResponse> {
    const response = await this.doRequest(
      'PUT',
      `Customers/${customerId}/TYRCards/${cardNumber}`,
      cardData,
      this.addBearerHeader(userToken)
    );

    if (!!response && response.ResponseCode === '000') {
      return true;
    }

    return {};
  }

  /**
   * Get customer historical transactions.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<Transaction[]>} - Promise that resolve transactions array when
   * available, empty array otherwise.
   * @memberof crmClientService
   */
  async transactionsGet(customerId: string, userToken: string): Promise<Transaction[]> {
    const response = await this.doRequest(
      'GET',
      `Customers/${customerId}/Transactions`,
      '',
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      const payload = JSON.parse(response.ResponseBody);
      return payload;
    }

    return [];
  }

  /**
   * Attach transaction to customer account.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {Transaction} - Transaction object to attach.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<CrmResponse>} - Promise that resolves CrmResponse object.
   * @memberof crmClientService
   */
  async transactionAttach(
    customerId: string,
    transaction: TransactionCreate,
    userToken: string
  ): Promise<CrmResponse> {
    const response = await this.doRequest(
      'POST',
      `Customers/${customerId}/AttachTransactions`,
      transaction,
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      return response;
    }

    return {};
  }

  /**
   * Create a customer donation request.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {DonationRequestCreate} donationRequest - Donation request object.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolve true if donation
   * request was created, false otherwise.
   * @memberof crmClientService
   */
  async customerDonationRequest(
    customerId: string,
    donationRequest: DonationRequestCreate,
    userToken: string
  ): Promise<boolean> {
    const response = await this.doRequest(
      'POST',
      `Customers/${customerId}/DonationRequests`,
      donationRequest,
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Create a guest donation request.
   *
   * @param {string} emailAddress - Guest email address.
   * @param {DonationRequestCreate} donationRequest - Donation request object.
   * @returns {Promise<boolean>} - Promise that resolve true if donation
   * request was created, false otherwise.
   * @memberof crmClientService
   */
  async guestDonationRequest(emailAddress: string, donationRequest: DonationRequestCreate) {
    const response = await this.doRequest(
      'POST',
      `Customers/${emailAddress}/DonationRequests`,
      donationRequest
    );

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Create donation request, user type agnostic.
   *
   * Use it to create donation request for authenticated or anonymous users
   * just omit the user token and request will be treated as guest, if provided
   * will be treated as customer.
   *
   * @param {string} userId - User identifier, CRM customer ID for
   * authenticated user or email address for guest.
   * @param {DonationRequestCreate} donationRequest - Donation request object.
   * @param {string} [userToken=null] - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolve true if donation
   * request was created, false otherwise.
   * @memberof crmClientService
   */
  async donationRequest(
    userId: string,
    donationRequest: DonationRequestCreate,
    userToken: string = ''
  ): Promise<boolean> {
    if (userToken !== '') {
      return this.customerDonationRequest(userId, donationRequest, userToken);
    } else {
      return this.guestDonationRequest(userId, donationRequest);
    }
  }

  /**
   * Create customer newsletter subscription.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {NewsletterSubscribeCreate} subscription - Newsletter subscription object.
   * @param {string} userToken - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolve true if subscription
   * was created, false otherwise.
   * @memberof crmClientService
   */
  async customerNewsletterSubscribe(
    customerId: string,
    subscription: NewsletterSubscribeCreate,
    userToken: string
  ): Promise<boolean> {
    const response = await this.doRequest(
      'PUT',
      `Customers/${customerId}/EmailPreferences`,
      subscription,
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Create guest newsletter subscription.
   *
   * @param {string} guestEmail - Guest email address.
   * @param {NewsletterSubscribeCreate} subscription - Newsletter subscription object.
   * @returns {Promise<boolean>} - Promise that resolve true if subscription
   * was created, false otherwise.
   * @memberof crmClientService
   */
  async guestNewsletterSubscribe(guestEmail: string, subscription: NewsletterSubscribeCreate) {
    const response = await this.doRequest(
      'PUT',
      `Customers/${guestEmail}/EmailPreferences`,
      subscription
    );

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Create newsletter subscription, user type agnostic.
   *
   * Use it to create subscription for authenticated or anonymous users just
   * omit the user token and request will be treated as guest, if provided will
   * be treated as customer.
   *
   * @param {string} userId - User identifier, CRM customer ID for
   * authenticated user or email address for guest.
   * @param {NewsletterSubscribeCreate} - Newsletter subscription object.
   * @param {string} [userToken=null] - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolve true if donation
   * request was created, false otherwise.
   * @memberof crmClientService
   */
  async newsletterSubscribe(
    userId: string,
    subscription: NewsletterSubscribeCreate,
    userToken: string = ''
  ): Promise<boolean> {
    if (userToken !== '') {
      return this.customerNewsletterSubscribe(userId, subscription, userToken);
    } else {
      return this.guestNewsletterSubscribe(userId, subscription);
    }
  }

  /**
   * Customer contact case create.
   *
   * @param {string} customerId - CRM customer ID.
   * @param {ContactCaseCreate} - Contact case object.
   * @param {string} - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolve true if contact case
   * was created, false otherwise.
   * @memberof crmClientService
   */
  async customerContactCaseCreate(
    customerId: string,
    contactCase: ContactCaseCreate,
    userToken: string
  ): Promise<boolean> {
    const response = await this.doRequest(
      'POST',
      `Customers/${customerId}/Cases`,
      contactCase,
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Guest contact case create.
   *
   * @param {string} guestEmail - Guest email address.
   * @param {ContactCaseCreate} - Contact case object.
   * @returns {Promise<boolean>} - Promise that resolve true if contact case
   * was created, false otherwise.
   * @memberof crmClientService
   */
  async guestContactCaseCreate(
    guestEmail: string,
    contactCase: ContactCaseCreate
  ): Promise<boolean> {
    const response = await this.doRequest('POST', `Customers/${guestEmail}/Cases`, contactCase);

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Contact case create, user type agnostic.
   *
   * Use it to create contact case for authenticated or guest users just omit
   * the user token and request will be treated as guest, if provided will be
   * treated as customer.
   *
   * @param {string} userId - User identifier, CRM customer ID for
   * authenticated user or email address for guest.
   * @param {ContactCaseCreate} - Contact case object.
   * @param {string} [userToken=null] - User session bearer token.
   * @returns {Promise<boolean>} - Promise that resolve true if contact case
   * was created, false otherwise.
   * @memberof crmClientService
   */
  async contactCaseCreate(
    userId: string,
    contactCase: ContactCaseCreate,
    userToken: string = ''
  ): Promise<boolean> {
    if (userToken !== '') {
      return this.customerContactCaseCreate(userId, contactCase, userToken);
    } else {
      return this.guestContactCaseCreate(userId, contactCase);
    }
  }

  /**
   * Add user bearer token in Authorization header on custom headers object that
   * will be passed to doRequest merging with the base request headers.
   *
   * @param {string} userToken - User session bearer token.
   * @param {Object} customHeaders - Custom headers object, when not passed an
   * empty custom headers object is intialized.
   * @returns {CustomHeaders}
   * @memberof crmClientService
   */
  addBearerHeader(userToken: string, customHeaders: Object = {}): CustomHeaders {
    return defaults({ Authorization: `Bearer ${userToken}` }, customHeaders);
  }

  /**
   * Get a customer FMK order by ID.
   *
   * @param {string} customerId - Customer CRM ID.
   * @param {number} orderId - The order ID.
   * @param {string} userToken - Authorization bearer token.
   * @returns Promise<OrderEntity> - Promise that resolves order entity object.
   * @memberof fmkClientService
   */
  customerFMKOrderGet(
    customerId: string,
    orderId: number,
    userToken: string
  ): Promise<OrderEntity> {
    const customHeaders: CustomHeaders = {
      customerType: 'REGISTERED',
      crmContactId: customerId,
      Authorization: `Bearer ${userToken}`,
    };

    return this.doRequest(
      'GET',
      `Customers/${customerId}/FestiveMealKits/${orderId}`,
      '',
      customHeaders
    );
  }

  /**
   * Delete a customer FMK order by ID.
   *
   * @param {string} customerId - Customer CRM ID.
   * @param {number} orderId - The order ID.
   * @param {string} userToken - Authorization bearer token.
   * @returns Promise<OrderEntity> - Promise that resolves order entity object.
   * @memberof fmkClientService
   */
  customerFMKOrderDelete(
    customerId: string,
    orderId: number,
    userToken: string
  ): Promise<OrderEntity> {
    const customHeaders: CustomHeaders = {
      customerType: 'REGISTERED',
      crmContactId: customerId,
      Authorization: `Bearer ${userToken}`,
    };

    return this.doRequest(
      'DELETE',
      `Customers/${customerId}/FestiveMealKits/${orderId}`,
      '',
      customHeaders
    );
  }

  /**
   * Validate customer account through 6 digit code for signup.
   *
   * @param {string} customerEmail - The customer email.
   * @param {string} code - The verification code.
   * @returns {Promise<bool>} - Promise that resolves boolean indicating code validation result.
   * @memberof baseCrmClientService
   */
  async customerValidateByCode(customerEmail: string, code: string): Promise<boolean> {
    const response = await this.doRequest('PUT', `Customers/${customerEmail}/Code`, { Code: code });

    if (response && response.ResponseCode === '000') {
      return true;
    }

    return false;
  }

  /**
   * Verify customer account ownership through 6 digit code for password reset.
   *
   * @param {string} customerEmail - The account customer email.
   * @param {string} code - The verification code.
   * @returns {Promise<string>} - Promise that resolve reset token on
   * successful validation or empty string otherwise.
   * @memberof baseCrmClientService
   */
  async customerPasswordResetByCode(customerEmail: string, code: string): Promise<string> {
    const response = await this.doRequest('PUT', `Customers/${customerEmail}/Password/Code`, {
      Code: code,
    });

    if (response && response.ResponseCode === '000') {
      const payload = JSON.parse(response.ResponseBody);
      const token = payload.PR_Token || '';
      return token;
    }

    return '';
  }

  /**
   * Provide an Airship Pass adaptive link URL.
   *
   * @param {string} customerId - Customer CRM ID.
   * @param {string} userToken - Authorization bearer token.
   * @returns {Promise<string>} - Promise that resolve adaptive link.
   * @memberof baseCrmClientService
   */
  async airshipPassAdaptiveLink(CRMCustomerID: string, userToken: string): Promise<string> {
    const response = await this.doRequest(
      'POST',
      `airship/links/adaptive/id/${CRMCustomerID}`,
      '',
      this.addBearerHeader(userToken)
    );

    if (response && response.ResponseCode === '000') {
      const payload = JSON.parse(response.ResponseBody);
      const adaptativeLink = payload.AdaptiveLinkUrl || '';
      return adaptativeLink;
    }

    return '';
  }

  /**
   * Check if Airship pass is installed for a given customer.
   *
   * @param {string} customerId - Customer CRM ID.
   * @param {string} userToken - Authorization bearer token.
   * @returns {Promise<boolean>} - Promise that resolve boolean, true when installed, false otherwise.
   * @memberof baseCrmClientService
   */
  async airshipPassIsInstalled(CRMCustomerID: string, userToken: string): Promise<boolean> {
    try {
      const response = await this.doRequest(
        'GET',
        `airship/pass/id/${CRMCustomerID}`,
        '',
        this.addBearerHeader(userToken)
      );

      if (response && response.ResponseCode === '000') {
        return false;
      }
    } catch (e) {
      // Handle pass installed response that Vlad return as 400 error.
      if (e.requestStatus === 400 && e.ResponseCode === '202') {
        return true;
      }

      // For other cases is a real exception.
      throw e;
    }
  }

}
