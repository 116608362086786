import ActionTypes from './action-types';
import { anomUser } from '../initial-state';
import { defaults } from 'lodash';

/**
 * User reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const UserReducer = (state, action) => {
  const payload = action.payload || {};

  // Through login/logout conditions, ensure some state is maintained to avoid UX issues.
  const maintainedState = {
    defaultStoreId: state.defaultStoreId,
    suggestedStore: state.suggestedStore,
  };
  const {latitude, longitude} = state;
  switch (action.type) {
    case ActionTypes.LOGOUT:
      // Avoid that on logout defaultStoreId is cleared due is where anonymous
      // user persist default store selection so after login/logout is lost.
      return defaults(maintainedState, anomUser({latitude, longitude}));
    case ActionTypes.LOGIN:
      return defaults(payload, maintainedState, anomUser({latitude, longitude}));

    case ActionTypes.SET_LOCATION:
      return defaults(payload, state);

    case ActionTypes.SET_STORE:
      return defaults(payload, state);

    case ActionTypes.SET_SUGGESTED_STORE:
      return defaults(payload, state);

    case ActionTypes.SET_COOKIE:
      return defaults(payload, state);

    default:
      return state;
  }
};

export default UserReducer;
