import ActionTypes from './action-types';
import InitialState from '../initial-state';

/**
 * Customer address reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const CustomerAddressReducer = (state, action) => {
  //console.log('Reducer action called:', action)

  switch (action.type) {
    case ActionTypes.LOGOUT:
      return InitialState.address;

    case ActionTypes.LOAD:
      return action.payload;

    case ActionTypes.UPDATE:
      state.push(action.payload);
      return action.payload;

    case ActionTypes.CREATE:
      state.push(action.payload);
      return state;

    default:
      return state;
  }
};

export default CustomerAddressReducer;
