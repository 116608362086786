import RecipeFilterActions from './actions';
import { remove } from 'lodash';

/**
 * Cart reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const recipeFiltersReducer = (state, action) => {
  const newState = { ...state };
  const payload = action.payload;
  switch (action.type) {
    case RecipeFilterActions.ADD_RECIPE_FILTER:
      if (newState.filters.indexOf(payload) === -1) {
        newState.filters.push(payload);
      }
      break;
    case RecipeFilterActions.REMOVE_RECIPE_FILTER:
      remove(newState.filters, n => n === payload);
      break;
    case RecipeFilterActions.CLEAR_ALL_RECIPE_FILTERS:
      Object.assign(newState, { filters: [] });
      break;
    case RecipeFilterActions.SET_RECIPE_PAGE:
      Object.assign(newState, { page: payload });
      break;

    default:
    // All good.
  }

  // console.log('recipeFilters state', newState);

  return newState;
};

export default recipeFiltersReducer;
