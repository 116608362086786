const CheckoutActionTypes = {
  OPTIN_MEMBER: 'CHECKOUT_OPT_IN_MEMBER',
  OPTIN_TERMS: 'CHECKOUT_OPT_IN_TERMS',
  COMPLETE_ORDER: 'CHECKOUT_COMPLETE_ORDER',
  GUEST_CHECKOUT: 'GUEST_CHECKOUT',
  RESET_GUEST_CHECKOUT: 'RESET_GUEST_CHECKOUT',
  UPDATE_CHECKOUT_CUSTOMER: 'UPDATE_CHECKOUT_CUSTOMER',
  RESET_CHECKOUT_CUSTOMER: 'RESET_CHECKOUT_CUSTOMER',
};

export default CheckoutActionTypes;
