import ActionTypes from './action-types';
import { anomCustomer } from '../initial-state';
import { defaults } from 'lodash';

/**
 * Customer reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const CustomerReducer = (state, action) => {
  //console.log('Reducer action called:', action)

  switch (action.type) {
    case ActionTypes.LOGOUT:
      return anomCustomer();

    case ActionTypes.LOAD:
      return defaults(action.payload, state);

    case ActionTypes.CHANGE:
      return {
        ...state,
        ...{ Email: action.Email },
      };

    case ActionTypes.CREATE:
      return {
        ...state,
        ...action.customerData,
      };

    case ActionTypes.CARD_BALANCE:
      return {
        ...state,
        CardBalance: action.CardBalance,
      };

    case ActionTypes.UPDATE:
      return {
        ...state,
        ...action.customer,
        CardNumber: action.customer.CardNumber || state.CardNumber,
        CardBalance: action.customer.PointsBalance || state.CardBalance,
      };

    case ActionTypes.SET_STORE:
      return defaults(action.payload, state);

    case ActionTypes.CARD_LOADED:
      return {
        ...state,
        CardNumber: action.payload.CardNumber,
        CardBalance: action.payload.PointsBalance || 0,
      };

    case ActionTypes.CARDS_LIST:
      return {
        ...state,
        CardsList: action.CardsList,
      };

    case ActionTypes.CARD_REMOVE:
      // console.log(state);

      return {
        ...state,
        CardNumber: '',
        PointsBalance: '',
      };

    case ActionTypes.CARD_UPDATE:
      return {
        ...state,
        CardNumber: action.CardNumber,
      };
    case ActionTypes.CARD_ADD:
      return {
        ...state,
        CardNumber: action.CardNumber,
      };
    case ActionTypes.CARD_REPLACE:
      return {
        ...state,
        CardNumber: action.CardNumber,
      };

    case ActionTypes.CARD_VALIDATE:
      return {
        ...state,
        ...{ newCard: action.Card },
      };

    default:
      return state;
  }
};

export default CustomerReducer;
