import { isObject } from 'lodash';
import https from 'https';

const agent = new https.Agent({
  rejectUnauthorized: false,
});

export const doRequest = async ({ headers, method, url, data, auth }) => {
  let options = {
    agent,
    method,
    headers,
  };

  if (!['GET', 'HEAD'].includes(method)) {
    if (isObject(data)) {
      options.body = JSON.stringify(data);
    } else {
      options.body = data || '';
    }
  }

  // console.log('REQUEST with', url, data, options);

  const res = await fetch(url, options);

  // Success responses.
  const successResponseCode = [201, 200];
  if (successResponseCode.indexOf(res.status) >= 0) {
    // DELETE calls don't provide data, skip JSON decode.
    if (method === 'DELETE') {
      return [];
    }

    const data = await res.json();
    data.requestStatus = res.status;
    return data;
  }

  // Error responses.
  let errorMessage;
  const errorResponseCode = [400, 404];
  if (errorResponseCode.indexOf(res.status) >= 0) {
    errorMessage = await res.text();
  } else {
    // 401, 403, 429, 500 and other errors throw general error message.
    errorMessage = 'API request failed.';
  }

  const requestError = new Error(errorMessage);
  requestError.requestStatus = res.status;
  throw requestError;
};
