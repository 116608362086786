import ActionTypes from './action-types';
import { historyOrders } from '../../store/initial-state';

/**
 * My Account reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const MyAccountReducer = (state, action) => {
  const newState = {
    ...state,
  };

  switch (action.type) {
    case ActionTypes.CLASS_HISTORY_ITEMS_LOADED:
      const classOrders = action.payload.orders || [];
      const classConcatOrders = newState.classOrders.items.concat(classOrders);
      const classLoadItems = state.classOrders.loadItems + classOrders.length;
      newState.classOrders.loadItems = classLoadItems;
      newState.classOrders.totalItems = action.payload.total || 0;
      newState.classOrders.items = classConcatOrders;
      return newState;

    case ActionTypes.CLASS_HISTORY_ITEMS_CLEAR:
      newState.classOrders = historyOrders();
      return newState;

    case ActionTypes.CLASS_HISTORY_ITEM_UPDATE:
      const updateClassOrder = newState.classOrders.items.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }

        return item;
      })

      newState.classOrders.items = updateClassOrder;
      return newState;

    case ActionTypes.GROCERY_HISTORY_ITEMS_LOADED:
      const groceryOrders = action.payload || [];
      const groceryConcatOrders = newState.groceryOrders.items.concat(groceryOrders);
      const groceryLoadItems = state.groceryOrders.loadItems + groceryOrders.length;
      newState.groceryOrders.loadItems = groceryLoadItems;
      newState.groceryOrders.items = groceryConcatOrders;
      return newState;

    case ActionTypes.GROCERY_HISTORY_ITEMS_CLEAR:
      newState.groceryOrders = historyOrders();
      return newState;

    default:
      return state;
  }
};

export default MyAccountReducer;
