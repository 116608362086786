/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const { wrapRootElement } = require('./src/system/gatsby');
const { has } = require('lodash');
// Only ever runs the first time a user accesses the site.
exports.onClientEntry = () => {
// kick off the polyfill!
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  // Ensure, as paths change, the body is always scrollable again.
  document.body.style.overflow = '';
  document.body.scrollTop = 0;

  if (has(window, 'notify') && prevLocation.href && location.href !== prevLocation.href) {
    if (window.notifyShow) {
      window.notify = [];
      window.notifyShow = false;
    }
    else {
      window.notifyShow = true;
    }
  }

};

exports.wrapRootElement = wrapRootElement;
