import React from 'react';
import { StateProvider } from '../store/state';
import MainReducer from '../store/main-reducer';
import InitialState from '../store/initial-state';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import crmClientService from '../services/crmClientService';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={crmClientService.recaptcha_sitekey}>
        <GoogleReCaptcha onVerify={(token) => {}} />
        <StateProvider initialState={InitialState} reducer={MainReducer}>
          {element}
        </StateProvider>
      </GoogleReCaptchaProvider>
    </>
  );
};
