const ModalActionTypes = {
  OPEN: 'MODAL_OPEN',
  CLOSE: 'MODAL_CLOSE',
};

export const modalTypes = {
  SLIDEIN: 'slideIn',
  FILTER: 'filter',
};

export const modalStatuses = {
  OPEN: 'open',
  CLOSED: 'closed',
};

export default ModalActionTypes;
