// Libs.
import { assign, defaults, without, has, get, findIndex, isEmpty } from 'lodash';
// State.
import ActionTypes from './action-types';
import { emptyCart } from '../initial-state';

/**
 * Cart reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const CartReducer = (state, action) => {
  let newState = {
    ...state,
  };

  // console.log('CartReducer', newState, action);
  switch (action.type) {
    case ActionTypes.ADD:
      // Add the new item.
      newState[action.cartType].lineItems.push(action.payload);
      newState[action.cartType].lineItemsCount = newState[action.cartType].lineItems.length;
      return newState;

    case ActionTypes.ADD_DISH:
      if (action.cartType === 'fmk') {
        const existingItem = findIndex(newState[action.cartType].lineItems, {
          itemId: action.payload.itemId,
        });
        if (existingItem >= 0) {
          newState[action.cartType].lineItems.splice(existingItem, 1, action.payload);
        } else {
          newState[action.cartType].lineItems.push(action.payload);
        }
      }
      return newState;

    case ActionTypes.REMOVE_DISH:
      newState[action.cartType].lineItems = state[action.cartType].lineItems.filter((item) => {
        if (has(item, 'card')) {
          return item.itemId !== action.payload.itemId;
        } else {
          return true;
        }
      });
      return newState;

    case ActionTypes.REMOVE:
      if (action.cartType === 'fmk') {
        const index = state[action.cartType].lineItems.find((item) => {
          return item.itemId === get(action, 'payload.itemId');
        });
        newState[action.cartType].lineItems = without(newState[action.cartType].lineItems, index);
        // if (newState[action.cartType].lineItems.find(item => (item.sides)))

        const checkFMK = newState[action.cartType].lineItems.find((item) => !isEmpty(item.dishes));
        if (!checkFMK) {
          const { fmk } = emptyCart();
          newState[action.cartType] = fmk;
        }
        // console.log(fmk)
      } else {
        newState[action.cartType].lineItems = state[action.cartType].lineItems.filter((item) => {
          if (has(action, 'payload.itemId')) {
            return item.itemId !== get(action, 'payload.itemId');
          }
          return String(item.id) !== String(action.payload.id);
        });
      }
      newState[action.cartType].lineItemsCount = newState[action.cartType].lineItems.length;
      return newState;

    case ActionTypes.UPDATE:
      newState[action.cartType].lineItems = state[action.cartType].lineItems.map((item) => {
        if (String(item.id) !== String(action.payload.id)) {
          return item;
        }

        return action.payload;
      });

      newState[action.cartType].lineItemsCount = newState[action.cartType].lineItems.length;
      return newState;

    case ActionTypes.SET_PICK:
      if (action.cartType === 'fmk') {
        newState[action.cartType].pickup = action.payload;
      }
      return newState;

    case ActionTypes.CART_URL:
      newState[action.cartType].checkoutUrl = action.payload;
      return newState;

    case ActionTypes.CLEAR:
      const clearState = emptyCart();
      newState[action.cartType] = clearState[action.cartType];
      return newState;

    default:
      return state;
  }
};

export default CartReducer;
