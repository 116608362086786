import ActionTypes from './action-types';
import { anomCheckoutCustomer } from '../initial-state';

/**
 * Checkout reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const CheckoutReducer = (state, action) => {
  let newState = {
    ...state,
  };

  switch (action.type) {
    case ActionTypes.OPTIN_MEMBER:
      newState.optIns.member = action.payload;
      return newState;

    case ActionTypes.OPTIN_TERMS:
      newState.optIns.terms = action.payload;
      return newState;

    case ActionTypes.GUEST_CHECKOUT:
      newState.isGuest = true;
      return newState;

    case ActionTypes.RESET_GUEST_CHECKOUT:
      newState.isGuest = false;
      return newState;

    case ActionTypes.UPDATE_CHECKOUT_CUSTOMER:
      Object.assign(newState.customer, action.payload);
      return newState;

    case ActionTypes.RESET_CHECKOUT_CUSTOMER:
      newState.customer = anomCheckoutCustomer();
      return newState;

    case ActionTypes.COMPLETE_ORDER:
      newState.orderComplete = action.payload;
      newState.isGuest = false;
      return newState;

    default:
      return state;
  }
};

export default CheckoutReducer;
