import ActionTypes from './action-types';

/**
 * Cart reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const classFiltersReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_FILTER:
      state.currentFilter = action.payload;
      return state;

    case ActionTypes.CLEAR_ALL_CLASS_FILTERS:
      state.currentFilter = '';
      state.tempLocations = [];
      state.locations = [];
      state.tempClassTypes = [];
      state.classTypes = [];
      state.tempClassDates = [];
      state.classDates = [];
      state.tempClassInstructors = [];
      state.classInstructors = [];
      return state;

    case ActionTypes.APPLY_LOCATION_FILTERS:
      state.currentFilter = '';
      state.locations = JSON.parse(JSON.stringify(state.tempLocations));
      return state;

    case ActionTypes.RESET_TEMP_LOCATIONS:
      state.currentFilter = '';
      state.tempLocations = JSON.parse(JSON.stringify(state.locations));
      return state;

    case ActionTypes.ADD_LOCATION:
      if (state.tempLocations.indexOf(action.payload) === -1) {
        state.tempLocations.push(parseInt(action.payload));
      }
      return state;

    case ActionTypes.REMOVE_LOCATION:
      state.tempLocations = state.tempLocations.filter((id) => id !== action.payload);
      return state;

    case ActionTypes.CLEAR_LOCATION_FILTERS:
      state.tempLocations = [];
      state.locations = [];
      return state;

    case ActionTypes.APPLY_CLASS_TYPE_FILTERS:
      state.currentFilter = '';
      state.classTypes = JSON.parse(JSON.stringify(state.tempClassTypes));
      return state;

    case ActionTypes.RESET_CLASS_TYPE_FILTERS:
      state.currentFilter = '';
      state.tempClassTypes = JSON.parse(JSON.stringify(state.classTypes));
      return state;

    case ActionTypes.ADD_CLASS_TYPE:
      if (state.tempClassTypes.indexOf(action.payload) === -1) {
        state.tempClassTypes.push(parseInt(action.payload));
      }
      return state;

    case ActionTypes.REMOVE_CLASS_TYPE:
      state.tempClassTypes = state.tempClassTypes.filter((id) => id !== action.payload);
      return state;

    case ActionTypes.CLEAR_CLASS_TYPE_FILTERS:
      state.tempClassTypes = [];
      state.classTypes = [];
      return state;

    case ActionTypes.APPLY_DATE_RANGE_FILTERS:
      state.currentFilter = '';
      state.classDates = JSON.parse(JSON.stringify(state.tempClassDates));
      return state;

    case ActionTypes.RESET_DATE_RANGE_FILTERS:
      state.currentFilter = '';
      state.tempClassDates = JSON.parse(JSON.stringify(state.classDates));
      return state;

    case ActionTypes.ADD_DATE_RANGE:
      state.tempClassDates = action.payload;
      return state;

    case ActionTypes.REMOVE_DATE_RANGE:
      return state;

    case ActionTypes.CLEAR_DATE_RANGE_FILTERS:
      state.tempClassDates = [];
      state.classDates = [];
      return state;

    case ActionTypes.APPLY_INSTRUCTORS_FILTERS:
      state.currentFilter = '';
      state.classInstructors = JSON.parse(JSON.stringify(state.tempClassInstructors));
      return state;

    case ActionTypes.RESET_TEMP_INSTRUCTORS:
      state.currentFilter = '';
      state.tempClassInstructors = JSON.parse(JSON.stringify(state.classInstructors));
      return state;

    case ActionTypes.ADD_INSTRUCTOR:
      if (state.tempClassInstructors.indexOf(action.payload) === -1) {
        state.tempClassInstructors.push(parseInt(action.payload));
      }
      return state;

    case ActionTypes.REMOVE_INSTRUCTOR:
      state.tempClassInstructors = state.tempClassInstructors.filter((id) => id !== action.payload);
      return state;

    case ActionTypes.CLEAR_INSTRUCTORS_FILTERS:
      state.tempClassInstructors = [];
      state.classInstructors = [];
      return state;

    default:
      return state;
  }
};

export default classFiltersReducer;
