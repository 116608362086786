/**
 * A selection of helper methods around accessing APIs for the site.
 * @file
 */
const getWindow = require('./getWindow');

let siteEnvLevel = '';

const { NODE_ENV = 'production', CRM_API_URL, LOFT_API_URL, GATSBY_API_URL } = process.env;

const developmentSites = [
  'http://localhost',
  'https://localhost',
  'http://localhost:8000',
  'https://localhost:8000',
  'https://dev--longos.netlify.app',
  'https://build--longos.netlify.app',
  'https://fmk--longos.netlify.app',
];

const testSites = [
  'https://test--longos.netlify.app',
  'https://dotcom-hotfix--longos.netlify.app',
  'https://fmk--longos.netlify.app',
  'https://app-test--longos-app.netlify.app',
  'https://app-hotfix--longos-app.netlify.app',
];

const productionSites = [
  // Full production sites
  'https://longos.com',
  'https://www.longos.com',
  'https://connect.longos.com',
  'https://app--longos-app.netlify.app',
  // Release Sites
  'https://app-release--longos-app.netlify.app',
  'https://release--longos.netlify.app',
  // Preview Sites
  'https://app-preview.lbfmarket.com',
  'https://web-preview.lbfmarket.com',
];

const appSites = [
  'https://app--longos-app.netlify.app',
  'https://app-dev--longos-app.netlify.app',
  'https://app-test--longos-app.netlify.app',
  'https://app-hotfix--longos-app.netlify.app',
  'https://app-release--longos-app.netlify.app',
  'https://connect-preview.longos.com',
  'https://app-preview.lbfmarket.com',
  'https://connect.longos.com',
];

const allSites = developmentSites + testSites + productionSites + appSites;

const siteTypes = {
  dev: 'dev',
  prod: 'prod',
  app: 'app',
  test: 'test',
};

const SITES = {
  dev: developmentSites,
  prod: productionSites,
  app: appSites,
  test: testSites,
};

// Check for current ENV
const isTestEnv = NODE_ENV === 'test';

/**
 * A definition of API types which can be leveraged for the path method below.
 * @type {{loft: string, content: string, crm: string}}
 */
const API_TYPE = {
  content: 'apiContent',
  crm: 'apiCrm',
  loft: 'apiLoft',
};

/**
 * A method to help obtain the correct API base URL based on the working environment
 * @param type
 * @param env
 * @returns {string}
 */
const baseApiPath = (type, env = '') => {
  let baseUrl = `/${type}/`;
  let siteUrl = getWindow('_siteUrl') || '';

  baseUrl += `${env || envLevel()}/`;

  // For integration test support
  if (isTestEnv) {
    if (type === API_TYPE.crm) {
      baseUrl = CRM_API_URL;
    } else if (type === API_TYPE.loft) {
      baseUrl = LOFT_API_URL;
    } else if (type === API_TYPE.content) {
      baseUrl = GATSBY_API_URL;
    }
  }

  return baseUrl;
};

/**
 * Determine the on site env API to leverage.
 * @returns {string}
 */
const envLevel = () => {
  // If set for the session, reuse it.
  if (siteEnvLevel) {
    return siteEnvLevel;
  }

  // Default to live for netlify preview branches
  let env = 'dev';

  let siteUrl = getWindow('_siteUrl') || '';

  if (!siteUrl && getWindow('location.host')) {
    siteUrl = `https://${getWindow('location.host')}`;
  }

  if (siteUrl) {
    // Determine the desired content API to access, either test or dev in all other cases.
    if (
      siteUrl === 'https://test--longos.netlify.app' ||
      siteUrl === 'https://dotcom-hotfix--longos.netlify.app' ||
      siteUrl === 'https://app-test--longos-app.netlify.app' ||
      siteUrl === 'https://app-hotfix--longos-app.netlify.app'
    ) {
      env = 'test';
    } else if (productionSites.indexOf(siteUrl) !== -1) {
      env = 'live';
    }
  }

  // Set to avoid reprocessing this and save cycles once found.
  siteEnvLevel = getWindow('_deployBranch');
  return env;
};

const siteOriginIs = (type = '', altPattern = '') => {
  let result = false;
  const origin = getWindow('location.origin');
  // First, do a check for SSR, and throw a non-bool in this event to avoid checks reacting to it.
  if (!origin) {
    result = -1;
  } // For pattern checks
  else if (altPattern !== '' && origin.indexOf(altPattern) !== -1) {
    result = true;
  } else if (!!siteTypes[type] && SITES[type] && SITES[type].indexOf(origin) !== -1) {
    // If a site type, and the origin exist within that site type, yay!
    result = true;
  }
  return result;
};

const getApiPath = (apiType, apiPath) => {
  let origin = '';
  const env = envLevel();
  // if (siteOriginIs(siteTypes.app) === true) {
  //   if (env !== 'live') {
  //     origin = `https://${env}--longos.netlify.app`;
  //   } else {
  //     origin = 'https://www.longos.com';
  //   }
  // }
  let url = `${origin}${baseApiPath(apiType)}${apiPath}`;
  return url;
};

module.exports = {
  allSites,
  API_TYPE,
  developmentSites,
  testSites,
  productionSites,
  appSites,
  siteTypes,
  baseApiPath,
  envLevel,
  siteOriginIs,
  getApiPath,
};
