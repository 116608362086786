import { defaultsDeep, assign, has } from 'lodash';
import { modalStatuses } from './modal/action-types';

const LOCAL_STORAGE_KEY = 'longos';

let localState = {};
export const emptyCart = () => {
  return {
    loft: {
      lineItems: [],
      lineItemsCount: 0,
      checkoutUrl: '',
    },
    fmk: {
      lineItems: [],
      lineItemsCount: 0,
      checkoutUrl: '',
      pickup: {
        storeId: '',
        date: {},
        time: '',
      },
    },
  };
};
try {
  if (localStorage) {
    localState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (has(localState, 'cart.lineItems')) {
      assign(localState, {
        cart: emptyCart()
      });
    }
  }
} catch (e) {}

export const anomUser = (save = {}) => {
  const obj = {
    CRMCustomerID: '',
    access_token: '',
    defaultStoreId: null,
    cookie: null,
    suggestedStore: {
      once: false,
      storeId: null,
    },
    location: {
      accuracy: null,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      latitude: null,
      longitude: null,
      speed: null,
      timestamp: null,
      error: null,
    },
    ...save,
  };
  try {
    if (localStorage) {
      localState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      obj.cookie = has(localState, 'user.cookie') ? localState.user.cookie : null;
    }
  } catch (e) {}
  return obj;
};

export const anomCustomer = () => {
  const obj = {
    EmailAddress: '',
    FirstName: '',
    LastName: '',
    Gender: '',
    Birthday: '',
    CardNumber: '',
    CardBalance: '',
    cookie: null,
    PreferredStoreNumber: '',
    CRMCustomerID: '',
    PrimaryPhone: '',
    PrimaryAddress_Street1: '',
    PrimaryAddress_Street2: '',
    PrimaryAddress_City: '',
    PrimaryAddress_PostalCode: '',
    PrimaryAddress_Province: 'ON',
    PrimaryAddress_Country: 'CA',
    EmailPreferences: 'LONGOS_FLYER:False:;LONGOS_TYR:False:;LONGOS_ERECEIPT:False:;',
    tyrCard: false,
  };
  try {
    if (localStorage) {
      localState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      obj.cookie = has(localState, 'user.cookie') ? localState.user.cookie : null;
    }
  } catch (e) {}
  return obj;
};

export const anomCheckoutCustomer = () => {
  return {
    EmailAddress: '',
    FirstName: '',
    LastName: '',
    CardNumber: '',
    PrimaryPhone: '',
    PrimaryAddress_Street1: '',
    PrimaryAddress_Street2: '',
    PrimaryAddress_City: '',
    PrimaryAddress_PostalCode: '',
    PrimaryAddress_Province: '',
    PrimaryAddress_Country: '',
    acceptsTerms: false,
  };
};



export const historyOrders = () => {
  return {
    loadItems: 0,
    totalItems: 0,
    items: [],
  };
};
export const initialModal = () => {
  return {
    status: modalStatuses.CLOSED,
    type: '',
    id: '',
    content: null,
    anchorEl: null,
    offsetPos: { top: 0, left: 0 },
  };
};

const InitialState = defaultsDeep(localState, {
  address: [],
  cart: emptyCart(),
  checkout: {
    optIns: {
      member: false,
      terms: false,
    },
    isGuest: false,
    customer: anomCheckoutCustomer(),
    orderComplete: null,
  },
  customer: anomCustomer(),
  ui: {
    bodyScrollable: true,
  },
  classFilters: {
    currentFilter: '',
    locations: [],
    tempLocations: [],
    classTypes: [],
    tempClassTypes: [],
    classDates: [],
    tempClassDates: [],
    classInstructors: [],
    tempClassInstructors: [],
  },
  modal: initialModal(),
  user: anomUser(),
  myAccount: {
    classOrders: historyOrders(),
    groceryOrders: historyOrders(),
  },
});

// Enforce some reset options on page load, such that the UX isn't hindered.
assign(InitialState, {
  ui: {
    bodyScrollable: true,
  },
  classFilters: {
    currentFilter: '',
    locations: [],
    tempLocations: [],
    classTypes: [],
    tempClassTypes: [],
    classDates: [],
    tempClassDates: [],
    classInstructors: [],
    tempClassInstructors: [],
  },
  recipeFilters: {
    filters: [],
    page: 1,
  },
  modal: initialModal(),
});

export default InitialState;

export { LOCAL_STORAGE_KEY };
