import { useEffect, useLayoutEffect } from 'react';
import { useStateValue } from '../store/state';

const mobileMenuLimit = 799;

export const allowBodyScroll = (toggle) => {
  const {
    documentElement,
    body,
    body: { clientWidth, scrollHeight, clientHeight },
  } = document;

  if (toggle) {
    body.style.overflow = '';
    documentElement.style['overflow-y'] = clientWidth > mobileMenuLimit ? '' : 'inherit';
  } else {
    body.style.overflow = 'hidden';
    documentElement.style['overflow-y'] = scrollHeight > clientHeight ? 'scroll' : 'inherit';
  }
};

export const useBodyScrollable = () => {
  const [
    {
      ui: { bodyScrollable },
    },
  ] = useStateValue();

  // If we're forcing close scroll, or the modal is open, disable body, and document depending on size depending.
  useEffect(() => allowBodyScroll(bodyScrollable), [bodyScrollable]);
};

export const useLockBodyScroll = (enabled) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const restoreBody = () => (document.body.style.overflow = originalStyle);
    // Prevent scrolling on mount
    if (enabled) {
      document.body.style.overflow = 'hidden';
    } else {
      restoreBody();
    }
    // Re-enable scrolling when component unmounts
    return () => restoreBody();
  }, [enabled]); // Empty array ensures effect is only run on mount and unmount
};
