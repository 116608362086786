const CartActionTypes = {
  ADD: 'CART_ITEM_ADD',
  REMOVE: 'CART_ITEM_REMOVE',
  UPDATE: 'CART_ITEM_UPDATE',
  CLEAR: 'CART_CLEAR',
  CART_URL: 'SET_CART_CHECKOUT',
  ADD_DISH: 'FMK_ADD_DISH',
  REMOVE_DISH: 'FMK_REMOVE_DISH',
  SET_PICK: "FMK_SET_PICK"
};

export default CartActionTypes;
