const ClassFiltersActionTypes = {
  SET_CURRENT_FILTER: 'SET_CURRENT_FILTER',
  CLEAR_ALL_CLASS_FILTERS: 'CLEAR_ALL_CLASS_FILTERS',

  ADD_LOCATION: 'ADD_LOCATION',
  REMOVE_LOCATION: 'REMOVE_LOCATION',
  APPLY_LOCATION_FILTERS: 'APPLY_LOCATION_FILTERS',
  RESET_TEMP_LOCATIONS: 'RESET_TEMP_LOCATIONS',
  CLEAR_LOCATION_FILTERS: 'CLEAR_LOCATION_FILTERS',

  ADD_CLASS_TYPE: 'ADD_CLASS_TYPE',
  REMOVE_CLASS_TYPE: 'REMOVE_CLASS_TYPE',
  APPLY_CLASS_TYPE_FILTERS: 'APPLY_CLASS_TYPE_FILTERS',
  RESET_CLASS_TYPE_FILTERS: 'RESET_CLASS_TYPE_FILTERS',
  CLEAR_CLASS_TYPE_FILTERS: 'CLEAR_CLASS_TYPE_FILTERS',

  ADD_DATE_RANGE: 'ADD_DATE_RANGE',
  REMOVE_DATE_RANGE: 'REMOVE_DATE_RANGE',
  APPLY_DATE_RANGE_FILTERS: 'APPLY_DATE_RANGE_FILTERS',
  RESET_DATE_RANGE_FILTERS: 'RESET_DATE_RANGE_FILTERS',
  CLEAR_DATE_RANGE_FILTERS: 'CLEAR_DATE_RANGE_FILTERS',

  ADD_INSTRUCTOR: 'ADD_INSTRUCTOR',
  REMOVE_INSTRUCTOR: 'REMOVE_INSTRUCTOR',
  APPLY_INSTRUCTORS_FILTERS: 'APPLY_INSTRUCTORS_FILTERS',
  RESET_TEMP_INSTRUCTORS: 'RESET_TEMP_INSTRUCTORS',
  CLEAR_INSTRUCTORS_FILTERS: 'CLEAR_INSTRUCTORS_FILTERS',
};

export const ClassSortTypes = {
  DATE: 'date',
  ALPHA: 'alpha',
};

export const ClassDateDisplay = {
  DATE: 'date',
  DATE_AND_TIME: 'date-and-time',
  HIDE: 'HIDE',
};

export default ClassFiltersActionTypes;
