// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
// State
import { StateContext } from '../store/state';
// Assets
import './rootLayout.scss';
// Hooks.
import { useBodyScrollable } from '../hooks/useBodyScrollable';

const RootLayout = ({ children }) => {
  useBodyScrollable();
  return <StateContext.Consumer>{(state) => <>{children}</>}</StateContext.Consumer>;
};

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootLayout;
