import ActionTypes from './action-types';
import InitialState from '../initial-state';

/**
 * UI reducer.
 *
 * @returns {object}
 *   The new state object.
 */
const uiReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.BODY_SCROLLABLE:
    case ActionTypes.BODY_UNSCROLLABLE:
      return {
        ...InitialState.ui,
        bodyScrollable: action.payload.scrollable,
      };

    default:
      return state;
  }
};

export default uiReducer;
