const UserActionTypes = {
  LOGIN: 'USER_LOGIN',
  LOGOUT: 'USER_LOGOUT',
  RESET: 'USER_RESET_PASSWORD',
  SET_LOCATION: 'USER_SET_LOCATION',
  SET_STORE: 'USER_SET_DEFAULT_STORE',
  SET_COOKIE: 'USER_SET_COOKIE',
  SET_SUGGESTED_STORE: 'USER_SET_SUGGESTED_STORE',
};

export default UserActionTypes;
